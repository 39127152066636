<template>
  <div class="guideRight" >
    <Affix :offset-top="0" @on-change="changeAffix" >
      <div class="fixed-card">
        <div class="card">
          <!-- <Button
            type="primary"
            size="large"
            long
            v-if="showAffix"
            large
            @click="gotoDeclare"
            >立即申报</Button
          > -->
          <!-- <div class="c-title flex-row">
            <img src="@/assets/images/r_star.png" alt="" />主管部门
          </div> -->
          <!-- <div style="text-align: center;padding: 15px 0">{{deptObject==null?'暂无数据':deptObject.deptName}}</div> -->
          <div class="flex-column fbbm" >
            <!-- <el-button type="warning" size="small" icon="el-icon-star-on" @click.stop="clickIsFollow">{{isFollow ? '取消关注' : '关注'}}</el-button> -->
            <!-- <el-button type="primary" size="small" @click="gotoDept">查看详情</el-button> -->
            <!-- <span>{{ deptObject.fbjg }}</span> -->
            <!-- <span class="fbbm_txt">{{ deptObject.deptName }}</span> -->
            <!-- <span style="margin-top:10px">咨询电话：{{ deptObject.consultation }}</span> -->
            <div
              v-show="sysPolicyExplain.name && sysPolicyExplain.name != 'null'"
            >
              <div class="c-title flex-row" style="text-align: left">
                <img
                  src="@/assets/images/policyExplain.jpg"
                  width="150px"
                  height="68px"
                  alt=""
                />
              </div>
              <p
                class="fbbm_txt"
                v-for="(item, index) in sysPolicyExplain.contentReceive"
                :key="index"
                @click="policyExplain(index, item.title)"
              >
                {{ item.title }}解读 &nbsp;|&nbsp;{{ sysPolicyExplain.name }}
              </p>
            </div>
<!--            <div class="c-title flex-row" v-show="projectObject.length > 0 && matchItemFlag == false">-->
            <div class="c-title flex-row" v-show="zcbj == false">
              <img src="@/assets/images/r_star.png" alt="" />相关项目
            </div>
            <div class="list-container">
<!--              <ul v-show="matchList.length > 0 && matchItemFlag == false">-->
              <ul v-show="zcbj == false">
                <li
                    v-for="item in projectObject"
                    :key="item.id"
                    @click="toGuide(item)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>

<!--            <div class="c-title flex-row" v-show="matchList.length > 0 && matchItemFlag == true">-->
            <div class="c-title flex-row" v-show="zcbj == true">
              <img src="@/assets/images/r_star.png" alt="" />匹配项目
            </div>
            <div class="list-container">
<!--              <ul v-show="matchList.length > 0 && matchItemFlag == true">-->
              <ul v-show="zcbj == true">
                <li
                    v-for="item in matchList"
                    :key="item.id"
                    @click="toGuide(item)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="c-title flex-row" v-if="policyInfo.queryurl">
              <img src="@/assets/images/r_star.png" alt="" />相关政策
            </div>
            <!-- <div
               v-if="
                sxid == 'e3d1fa8f6e5c4620ab28ba63d1b272fb' ||
                sxid == '7d7cd03229954c269756ef4e4df06a5c'
              "
            >
              <a
                class="fbbm_txt"
                style="display: inline-block"
                target="_blank"
                href="http://www.deyang.gov.cn/gk/hqzc/dyfzc/1541061.htm"
                >德阳市促进数字经济发展十条措施</a
              >
              <a
                class="fbbm_txt"
                style="display: inline-block"
                target="_blank"
                href="http://jxj.deyang.gov.cn/gk/xxgkml/fdzdgknr/zcwj/26365.htm"
                >德阳市工业互联网创新发展专项资金支持实施细则</a
              >
            </div>
            <a
              v-else
              class="fbbm_txt"
              :href="downloadUrl + item.fileid"
              download
              v-for="(item, index) in pdfFileData"
              :key="index"
              >{{ item.filename }}</a
            > -->
            <a
              v-if="policyInfo.queryurl"
              class="fbbm_txt"
              style="
                display: inline-block;
                width: 100%;
                white-space: wrap;
                word-break: break-word;
              "
              target="_blank"
              :href="policyInfo.queryurl"
              >政策原文{{ policyInfo.queryurl }}
            </a>
            <div class="c-title flex-row" v-show="appendixData.length > 0">
              <img src="@/assets/images/r_star.png" alt="" />相关附件
            </div>
            <div style="padding-left: 20px;cursor:pointer;" v-show="appendixData.length > 0">
              <div v-for="(item, index) in appendixData" :key="index" style="position: relative;">
                <a
                  style="
                    display: inline-block;
                    width: 100%;
                    white-space: wrap;
                    word-break: break-word;
                  "
                  download
                  :href="item.url"
                  >{{ item.name }}
                </a>
                <a v-show="item.name.indexOf('.pdf')!=-1" style="position:absolute;right:-30px;top:0;" href="javascript:void(0);" @click.stop="preview(item.fileId)">预览</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Affix>
    <div class="content-box">
      <!-- <div class="relative-policy">
        <div class="r-title">
          {{ isPlolicy == "0" ? "相关政策" : "相关通知" }}
        </div>
        <ul>
          <li
            class="r-li"
            v-for="(item, i) in relativePolicy"
            :key="i"
            @click="gotoPolicy(item)"
          >
            <a class="max-two-line r-name">{{ item.name }}</a>
            <div class="flex-row-between r-content">
              <span class="max-one-line r-dept">{{ item.dept_name }}</span>
              <span class="r-date">{{ item.date }}</span>
            </div>
          </li>
        </ul>
      </div> -->
      <!-- <div class="relative-policy">
        <div class="r-title">相关专项</div>
        <ul>
          <li
            class="r-li"
            v-for="(item, i) in zhuanxiangList"
            :key="i"
            @click="gotoProject(item)"
          >
            <a class="max-two-line r-name">{{ item.name }}</a>
            <div class="flex-row-between r-content">
              <span class="max-one-line r-dept">{{ item.deptName }}</span>
              <span class="r-remark">{{ item.remark }}</span>
            </div>
          </li>
        </ul>
      </div> -->
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="60%"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="dialogContent">
        <div>
          <el-scrollbar style="width: 95%">
            <el-form
              ref="sysPolicyExplain"
              :model="sysPolicyExplain"
              :rules="rules"
              label-width="80px"
              :inline="true"
            >
              <el-row>
                <el-col :span="24" align="center">
                  <el-form-item label="" prop="name">
                    <p class="jdTitle">{{ sysPolicyExplain.name }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :offset="1" align="right">
                  <el-form-item label="所属区划:" prop="areaid">
                    <!-- <el-cascader
                      disabled
                      :options="optionsArea"
                      filterable
                      clearable
                      v-model="sysPolicyExplain.areaid"
                      style="width: 100%"
                      :show-all-levels="false"
                      placeholder=""
                    >
                    </el-cascader> -->
                    <el-input
                      disabled
                      v-model="sysPolicyExplain.areaname"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11" align="left">
                  <el-form-item label="颁布部门:" prop="deptid">
                    <el-cascader
                      disabled
                      :options="optionsDept"
                      filterable
                      clearable
                      v-model="sysPolicyExplain.deptid"
                      :props="{ checkStrictly: true }"
                      style="width: 100%"
                      :show-all-levels="false"
                      placeholder=""
                    >
                    </el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>

              <!-- <el-form-item label="排序" prop="sort">
                <el-input
                  disabled
                  v-model="sysPolicyExplain.sort"
                  placeholder="请输入排序"
                />
              </el-form-item> -->
              <el-tabs v-model="editableTabsValue" type="card">
                <el-tab-pane
                  v-for="item in sysPolicyExplain.contentReceive"
                  :key="item.name"
                  :label="item.title"
                  :name="item.name"
                >
                  <el-form-item label="解读内容">
                    <editor disabled v-model="item.content" :min-height="192" />
                  </el-form-item>
                </el-tab-pane>
              </el-tabs>
            </el-form>
          </el-scrollbar>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { optionsArea, optionsDept } from "@/views/findList/areaDept.js";
import Editor from "@/components/Editor";
export default {
  name: "guideRight",
  components: { Editor },
  props: {
    policyInfo: {
      type: Object,
      default: () => {},
    },
    isPlolicy: {
      type: String,
      default: function () {
        return "0";
      },
    },
    //有deptName和deptId两个字段
    deptObject: {
      type: Object,
      default: function () {
        return null;
      },
    },
    projectObject: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: function () {
        return "0";
      },
    },
    deptid: {
      type: String,
      default: function () {
        return "0";
      },
    },
    code: {
      type: String,
    },
    sxid: {
      type: String,
    },
    zcbj: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    fileObj: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // this.getPolicyRelatedFile();
    this.getAppendix();
    this.getArea();
    this.getTree();
    this.getPolicyExplain(this.id);
  },
  data() {
    return {
      optionsArea: [],
      optionsDept: [],
      rules: {},
      dialogTitle: "政策解读",
      dialogVisible: false,
      editableTabsValue: "3",
      sysPolicyExplain: {
        // sort: 1,
        // contentReceive: [
        //   {
        //     title: "图片",
        //     type: "1",
        //     name: "1",
        //     status: "0",
        //     content: "",
        //   },
        //   {
        //     title: "图文",
        //     type: "2",
        //     name: "2",
        //     status: "0",
        //     content: "",
        //   },
        //   {
        //     title: "文字",
        //     type: "3",
        //     name: "3",
        //     status: "0",
        //     content: "",
        //   },
        // ],
      },
      pdfFileData: [], //相关政策文件
      appendixData: [], //附件
      collectItemId:
        this.deptObject.deptId == "0" ? "" : this.deptObject.deptId,
      showAffix: false,
      isFollow: false, //是否已经关注 已经关注为true
      relativePolicy: [
        {
          name: "“通济才智”工程引进培养重点人才分类认定及评定实施细则（修订）",
          dept_name: "工信局",
          remark: "资金支持",
          date: "2020-10-09",
        },
        {
          name: "“通济才智”工程引进培养重点人才分类认定及“通济才智”工程引进培养重点人才分类认定及评定实施细则（修订）“通济才智”工程引进培养重点人才分类认定及评定实施细则（修订）",
          dept_name: "发展改革委发展改革委发展改革委",
          remark: "资金支持",
          date: "2020-10-11",
        },
      ],
      zhuanxiangList: [
        {
          name: "“通济才智”工程引进培养重点人才分类认定及评定实施细则（修订）",
          deptName: "工信局",
          remark: "资金支持",
        },
        {
          name: "“通济才智”工程引进培养重点人才分类认定及“通济才智”工程引进培养重点人才分类认定及评定实施细则（修订）“通济才智”工程引进培养重点人才分类认定及评定实施细则（修订）",
          deptName: "发展改革委",
          remark: "资金支持",
        },
        {
          name: "“通济才智”工程引进培养重点人才分类认定及",
          deptName: "发展改革委",
          remark: "资金支持",
        },
        {
          name: "“通济才智”工程引进培养重点人才分类认定及",
          deptName: "发展改革委",
          remark: "资金支持",
        },
        {
          name: "“通济才智”工程引进培养重点人才分类认定及评定实施细则（修订）“通济才智”工程引进培养重点人才分类认定及评定实施细则（修订）",
          deptName: "工信局",
          remark: "资金支持",
        },
        {
          name: "“通济才智”工程引进培养重点人才分类认定及",
          deptName: "发展改革委",
          remark: "资金支持",
        },
        {
          name: "“通济才智”工程引进培养重点人才分类认定及",
          deptName: "发展改革委",
          remark: "资金支持",
        },
        {
          name: "“通济才智”工程引进培养重点人才分类认定及",
          deptName: "发展改革委",
          remark: "资金支持",
        },
      ],
      currentDeptName: this.deptName,
      currentDeptId: "",
      deptData: this.deptObject,
      downloadUrl:
        process.env.VUE_APP_BASE_API +
        "/dev-api/fileManageApi/common/fileView/",
      pdfUrl:
        process.env.VUE_APP_BASE_API +
        "/common/pdfView?file=" +
        process.env.VUE_APP_BASE_API +
        "/common/fileView/",
      matchItemFlag: false,//匹配项目判断标志 false:不展示匹配项目
      matchList: [],//匹配项目列表
    };
  },
  mounted() {
    this.matchItemFlag = this.$route.query.key
    if(this.matchItemFlag == "true"){
      this.matchItemFlag = true;
    }else {
      this.matchItemFlag = false;
    }

    this.matchList = JSON.parse(sessionStorage.getItem("matchList"));

    this.getRelevance();
    this.getCollectList();
  },
  methods: {
    preview(fileId) {
      let href = this.pdfUrl + fileId + "/view";
      window.open(href, "_blank");
    },
    toGuide(item) {
      this.$store.commit("updateCurrentGuideId", item.id);
      if (this.activeTab == 0) {
        this.$router.push({
          path: "/guideSuqiu",
          query: {
            name: item.name,
            itemType: item.itemtype,
            isAdded: item.isAdded,
            linkType: true,
          },
        });
      } else {
        this.$router.push({
          path: "/guideSuqiu",
        });
      }
    },
    // 获取区划
    getArea() {
      this.optionsArea = optionsArea;
    },
    // 获取部门
    getTree() {
      // this.optionsDept = optionsDept;
      let url = "/dev-api/web/dept/treeCascader";
      this.$httpApi.get(url, {}).then((res) => {
        this.optionsDept = res.data;
      });
    },
    policyExplain(index, title) {
      // 测试数据
      // let id = this.id;
      // this.getPolicyExplain(id);
      // this.sysPolicyExplain.deptid = "3947307944844120064";
      // this.sysPolicyExplain.areaid = "510699000000";
      // this.sysPolicyExplain.name = "标题标题标题标题标题标题";
      // this.dialogVisible = true;
      sessionStorage.setItem(
        "policyExplainData",
        JSON.stringify(this.sysPolicyExplain)
      );
      sessionStorage.setItem("policyExplainDataIndex", index);
      this.$router.push({
        path: "/notice/policyExplainPage",
        query: {
          policyId: this.id,
          title: title,
        },
      });
    },
    // 获取政策解读
    getPolicyExplain(policyid) {
      let url = "/dev-api/basicinfo/baseitem/queryExplainByZcid";
      let params = {
        policyid: policyid,
      };
      this.$httpApi.post(url, params).then((response) => {
        if (response.code == 200 && Object.keys(response.data).length > 0) {
          this.sysPolicyExplain = response.data;
          let arr = this.sysPolicyExplain.deptid.split(",");
          this.sysPolicyExplain.deptid = arr;
          if (
            this.sysPolicyExplain.contentReceive &&
            this.sysPolicyExplain.contentReceive[0]
          ) {
            this.editableTabsValue =
              this.sysPolicyExplain.contentReceive[0].name;
          }
          // 过滤没有的解读内容的tab
          let temp = this.sysPolicyExplain.contentReceive.filter((item) => {
            return item.content.length > 0;
          });
          this.sysPolicyExplain.contentReceive = temp;
        }
      });
    },
    // 根据政策编码查相关政策文件
    getPolicyRelatedFile() {
      let param = {
        code: this.code,
      };
      let url = "/dev-api/sqPolicy/file/selectFileByCode";
      this.$httpApi.post(url, param).then((res) => {
        if (res.code == 200) {
          let fileIds = res.data.fileId.split(",");
          let fileName = res.data.fileName.split(",");
          fileIds.forEach((item, index) => {
            this.pdfFileData.push({
              fileid: item,
              filename: fileName[index],
            });
          });
        }
      });
    },
    // 根据政策编码查附件
    getAppendix() {
      let that = this;
      let code = sessionStorage.getItem('policyCode')
      let url = "/dev-api/fileRecord/list/" + code;
      that.$httpApi.get(url, {}).then((res) => {
        if (res.code == 200) {
          res.rows.forEach((item) => {
            that.appendixData.push({
              name: item.filename,
              fileId: item.fileid,
              url: that.downloadUrl + item.fileid,
              id: item.id,
            });
          });
        }
      });
    },
    //查询是否关注了该部门
    getCollectList() {
      if (this.$store.getters.getUserInfo) {
        let identify = this.$store.getters.getUserInfo.shxydm;
        let param = {
          identify: identify,
          type: "7",
          collectionId: this.deptObject.deptId,
          pageNum: 1,
          pageSize: 100,
        };
        let url = "/dev-api/collection/list";
        console.log("关注参数：", param);
        this.$httpApi
          .get(url, param)
          .then((res) => {
            console.log("收藏部门", res);
            if (res.code == 200) {
              res.total === 0
                ? (this.isFollow = false)
                : (this.isFollow = true);
              this.collectItemId = res.rows[0].id || "";
            }
          })
          .catch((err) => {});
      }
    },
    clickIsFollow() {
      if (this.$store.getters.getUserInfo) {
        if (this.isFollow) {
          this.cancelCollect();
        } else {
          this.collect();
        }
      } else {
        this.$emit("clickIsFollow", this.item);
      }
    },
    gotoDept() {
      this.$router.push({
        path: "/deptInfo/" + this.deptObject.deptId,
      });
    },
    changeAffix(flag) {
      this.showAffix = flag;
    },
    gotoDeclare() {
      this.$emit("gotoDeclare");
    },
    gotoPolicy(item) {
      let { href } = this.$router.resolve({
        path: "/notice/detail/" + item.id + "?type=" + item.itemtype,
      });
      window.open(href, "_blank");
    },
    gotoProject(item) {
      // this.$message({
      //   type: "warning",
      //   message: "功能开发中,敬请期待",
      // });
    },
    getRelevance() {
      let params = {
        pageNum: 1,
        pageSize: 10,
        policyid: this.id,
      };
      console.log(params);
      this.$httpApi
        .get(`/dev-api/basicinfo/baseitem/policyItemlist`, params)
        .then((res) => {
          console.log("关联的事项和公告:", res);
          if (res.code === 200) {
            this.relativePolicy = res.rows;
          }
        })
        .catch((err) => {
          this.$message({
            message: "网络异常，请稍后重试",
            type: "error",
          });
        });
    },
    //关注  TYPE为7为部门关注
    collect() {
      let id = this.deptObject.deptId;
      let identify = this.$store.getters.getUserInfo.shxydm;
      let name = this.deptObject.deptName;
      let collectedType = "7";
      let param = {
        collectionName: name,
        collectionId: id,
        identify: identify,
        type: collectedType,
      };
      console.log("收藏参数：", param);
      this.$httpApi
        .post("/dev-api/collection/install", param)
        .then((res) => {
          console.log("添加收藏" + JSON.stringify(res));
          if (res.code == 200 && res.data) {
            this.collectItemId = res.id;
            this.isFollow = true;
          }
        })
        .catch((err) => {});
    },
    //取消收藏
    cancelCollect() {
      let collectItemId = this.collectItemId;
      console.log("取消收藏参数：", collectItemId);
      this.$httpApi
        .post("/dev-api/collection/delete/" + collectItemId, {})
        .then((res) => {
          console.log("取消收藏" + JSON.stringify(res));
          if (res.code == 200) {
            this.collectItemId = "";
            this.isFollow = false;
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/flex.scss";
.list-container {
  max-height: 300px; /* 设置最大高度 */
  overflow: auto; /* 添加滚动条 */
}

.fixed-card {
  background: #ffffff;
  z-index: 999;
  padding: 0;
  margin-top: 48px;
}

.card {
  // border: 1px solid rgb(226, 226, 226);
  // margin-bottom: 20px;
  .c-title {
    padding: 10px;
    font-size: 16px;
    min-height: 3rem;
    color: #d81e06;
    font-weight: 700;
    img {
      margin-right: 5px;
    }
  }
  .fbbm {
    padding-bottom: 10px;
    min-height: 5rem;
    .fbbm_txt {
      cursor: pointer;
      padding-left: 20px;
      margin: 10px 0;
      &:hover {
        color: #03b1ff;
      }
    }
  }
  ul {
    margin-left: 36px;
    li {
      cursor: pointer;
      list-style: disc;
      margin: 15px 0;
      &:hover {
        color: #03b1ff;
      }
    }
  }

  .c-content {
    padding: 14px 0;
    font-size: 15px;
    text-align: center;
  }
}

.relative-policy {
  border: 1px solid rgb(226, 226, 226);
  margin-bottom: 30px;

  .r-title {
    font-size: 15px;
    padding: 10px;
    color: #000;
  }

  .r-li {
    border-top: 1px solid rgb(226, 226, 226);
    padding: 12px 10px;

    .r-name {
      color: #000;
      font-size: 13px;
      width: 100%;
    }

    .r-content {
      font-size: 11px;
      color: #000;
      padding-top: 8px;

      .r-dept {
        margin-right: 16px;
      }

      .r-remark {
        // color: #f00;
        flex-shrink: 0;
      }

      .r-date {
        flex-shrink: 0;
      }
    }
  }
}
::v-deep .el-input.is-disabled .el-input__inner {
  border-color: #fff !important;
  background-color: #fff !important;
  color: #aaa;
}
::v-deep .el-icon-arrow-down:before {
  content: "";
}
::v-deep .el-form-item__label {
  color: #aaa;
}
.jdTitle {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
</style>
